import { Text, Container, SimpleGrid, createStyles, Title, Flex, Group, Button } from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { Link } from "react-scroll"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    backgroundImage: "repeating-linear-gradient(150deg,rgba(0,0,0,.02),rgba(0,0,0,.02) 1px,transparent 0,transparent 41px),repeating-linear-gradient(-150deg,rgba(0,0,0,.02),rgba(0,0,0,.02) 1px,transparent 0,transparent 41px)",
    backgroundSize: "82px 47px",
    backgroundAttachment: "fixed",
    backgroundPosition: "top center",
  },

  container: {
    alignItems: "flex-start",
    gap: 24,
  },

  buttonBox: {
    marginTop: 48,

    [theme.fn.smallerThan(900)]: {
      marginTop: 32,
    },
  },

  title: {
    marginBottom: 64,
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  text: {
    fontSize: 18,

    [theme.fn.smallerThan(768)]: {
      fontSize: 16,
    },
  },
}))

export function YouLearned(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)

  const offset = md ? -48 : xs ? -36 : -72
  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <Title className={classes.title} order={2} align="center">
          В процессе обучения&nbsp;
          <Text
            span
            variant="gradient"
            gradient={{
              from: "teal.7",
              to: "green.7",
              deg: 105,
            }}>
            вы научитесь
          </Text>
        </Title>

        <SimpleGrid
          verticalSpacing={48}
          spacing={48}
          cols={2}
          breakpoints={[
            { maxWidth: "sm", cols: 1, spacing: 24, verticalSpacing: 24 },
          ]}
        >
          <Flex className={classes.container}>
            <Image objectFit="contain" src="assets/packs/organize.png" sx={{ width: 32, minWidth: 32 }}/>
            <Text className={classes.text}>
              Составлять план питания для своих клиентов с учетом индивидуальных особенностей организма
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <Image objectFit="contain" src="assets/packs/vegetables.png" sx={{ width: 32, minWidth: 32 }}/>
            <Text className={classes.text}>
              Подбирать пищевую корзину индивидуально под каждого подопечного
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <Image objectFit="contain" src="assets/packs/calories.png"
                   sx={{ width: 32, minWidth: 32 }}/>
            <Text className={classes.text}>
              На практике использовать методы контроля энергоемкости рациона с (и без) подсчета калорий на основе
              международных рекомендаций
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <Image objectFit="contain" src="assets/packs/intestine.png"
                   sx={{ width: 32, minWidth: 32 }}/>
            <Text className={classes.text}>
              Выявлять расстройства пищевого поведения и оказывать соответствующую помощь в рамках компетенций
              нутрициолога
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <Image objectFit="contain" src="assets/packs/digestive-system.png" sx={{ width: 32, minWidth: 32 }}/>
            <Text className={classes.text}>
              Разбираться в физиологии и биохимии человека
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <Image objectFit="contain" src="assets/packs/vitamins.png"
                   sx={{ width: 32, minWidth: 32 }}/>
            <Text className={classes.text}>
              Определять нормы потребления макро и микронутриентов
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <Image objectFit="contain" src="assets/packs/lifestyle.png"
                   sx={{ width: 32, minWidth: 32 }}/>
            <Text className={classes.text}>
              Формировать здоровые пищевые привычки у своих подопечных
            </Text>
          </Flex>
          <Flex className={classes.container}>
            <Image objectFit="contain" src="assets/packs/research.png"
                   sx={{ width: 32, minWidth: 32 }}/>
            <Text className={classes.text}>
              Методам поиска и интерпретации научных исследований для практической деятельности
            </Text>
          </Flex>
        </SimpleGrid>


        <Group className={classes.buttonBox} position="center">
          <Flex align="center" justify="center" direction="column">
            <Button
              variant="gradient"
              gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
              size={md ? "md" : "lg"}
              radius="xl"
              to={"prices"}
              smooth={true}
              duration={1000}
              component={Link}
              offset={offset}

            >
              Стать студентом
            </Button>
          </Flex>
        </Group>
      </Container>

    </div>
  )
}
