import { createStyles } from "@mantine/core"
import React from "react"
import { useMediaQuery, useWindowScroll } from "@mantine/hooks"

// ----------------------------------------------------------------------

const useStyles = createStyles(() => ({
  image: {
    objectFit: "contain",
    verticalAlign: "middle",
    height: 50,
    cursor: "pointer",
  },
}))

type Props = {
  className?: string
};

export function Logo({ className = "" }: Props): React.JSX.Element {
  const [ scroll, scrollTo ] = useWindowScroll()
  const { classes } = useStyles()
  const matched = useMediaQuery(`(max-width: 540px)`)
  const logo = matched ? "logo_small.png" : "logo.png"

  const handleClick = () => scrollTo({ y: 0 })

  const width = matched ? 66 : 239

  return (
    <div className={className}>
      <img onClick={handleClick} className={classes.image} style={{ width }} src={`assets/${logo}`}
           alt="Логотип Body Coach"/>
    </div>
  )
}
