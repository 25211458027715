import axios, { type AxiosResponse } from "axios"
import { API_URL } from "../config"

// ----------------------------------------------------------------------

export type ErrorResponse = {
  message: string
}

export interface MailRequest {
  email: string
  name: string
  phone: string
  target: string
}

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

export function sendParticipationRequest(_body: Record<string, string>): Promise<AxiosResponse<{
  message: string
}, ErrorResponse>> {
  const url = new URL(window.location.href)
  const params = url.searchParams
  const referer = window.document.referrer

  const utm_source = params.get("utm_source") || ""
  const utm_medium = params.get("utm_medium") || ""
  const utm_campaign = params.get("utm_campaign") || ""
  const utm_content = params.get("utm_content") || ""
  const utm_term = params.get("utm_term") || ""

  const body = {
    ..._body,
    referer,
    utmData: {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
    },
  }

  return api.post("mail", body)
}
