import { forwardRef } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Box } from "@mantine/core"
import image from "shared/assets/placeholder.svg"
import { getRatio } from "./utils"
import { type ImageProps } from "./types"

// ----------------------------------------------------------------------

export const Image = forwardRef<HTMLSpanElement, ImageProps>(
  function Image(
    {
      ratio,
      disabledEffect = false,
      alt,
      src,
      afterLoad,
      delayTime,
      threshold,
      beforeLoad,
      delayMethod,
      placeholder,
      wrapperProps,
      scrollPosition,
      effect = "blur",
      visibleByDefault,
      wrapperClassName,
      useIntersectionObserver,
      objectFit = "cover",
      sx,
      ...other
    },
    ref,
  ) {
    const content = (
      <Box
        component={LazyLoadImage}
        alt={alt}
        src={src}
        afterLoad={afterLoad}
        delayTime={delayTime}
        threshold={threshold}
        beforeLoad={beforeLoad}
        delayMethod={delayMethod}
        placeholder={placeholder}
        wrapperProps={wrapperProps}
        scrollPosition={scrollPosition}
        visibleByDefault={visibleByDefault}
        effect={disabledEffect ? undefined : effect}
        useIntersectionObserver={useIntersectionObserver}
        wrapperClassName={wrapperClassName || "component-image-wrapper"}
        placeholderSrc={disabledEffect ? "/assets/transparent.png" : image}
        //
        sx={{
          width: "100%",
          height: "100%",
          objectFit,
          verticalAlign: "bottom",
          ...(!!ratio && {
            top: 0,
            left: 0,
            position: "absolute",
          }),
        }}
      />
    )

    return (
      <Box
        ref={ref}
        component="span"
        className="component-image"
        sx={{
          overflow: "hidden",
          position: "relative",
          verticalAlign: "bottom",
          display: "inline-block",
          ...(!!ratio && {
            width: "100%",
          }),
          "& span.component-image-wrapper": {
            width: "100%",
            height: "100%",
            verticalAlign: "bottom",
            backgroundSize: "cover !important",
            ...(!!ratio && {
              paddingTop: getRatio(ratio),
            }),
          },
          ...sx,
        }}
        {...other}
      >
        {content}
      </Box>
    )
  },
)
