import { Text, Container, Title, createStyles, Button, Flex } from "@mantine/core"
import React from "react"
import { Image } from "../shared/ui"
import { useMediaQuery } from "@mantine/hooks"
import { Link } from "react-scroll"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => {
  return ({
    container: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan("md")]: {
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      },

      [theme.fn.smallerThan("xs")]: {
        paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
        paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      },
    },

    contentWrapper: {
      padding: 64,
      borderRadius: theme.radius.xl,
      backgroundColor: theme.white,
      gap: 64,

      [theme.fn.smallerThan(1024)]: {
        padding: 48,
        gap: 24,
      },

      [theme.fn.smallerThan(768)]: {
        padding: 20,
        gap: 24,
        flexDirection: "column",
      },
    },

    content: {
      maxWidth: 640,
      gap: 24,
      fontSize: theme.fontSizes.lg,

      [theme.fn.smallerThan(768)]: {
        order: 1,
        fontSize: theme.fontSizes.md,
      },
    },

    image: {
      justifySelf: "flex-start",
      minWidth: 248,
      margin: "0 auto",

      [theme.fn.smallerThan(768)]: {
        justifySelf: "unset",
        order: 0,
      },
    },

    title: {
      fontSize: 24,
    },

    btn: {
      [theme.fn.smallerThan(768)]: {
        alignSelf: "center",
      },
    },
  })
})

// ----------------------------------------------------------------------

export function Gpt(): React.JSX.Element {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)

  const offset = md ? -48 : xs ? -36 : -72

  return (
    <Container size={1320} className={classes.container}>
      <Flex align="center" justify="center" className={classes.contentWrapper}>
        <Flex direction="column" align="flex-start" className={classes.content}>
          <Title className={classes.title}>
            BodyCoach GPT - главная фишка обучающего курса &#34;Профессия: Нутрициолог&#34;
          </Title>
          <Text>
            Онлайн-куратор с искусственным интеллектом. Помогает в изучении материала и отвечает на ваши вопросы в сфере
            фитнеса и нутрициологии. На связи 24/7
          </Text>
          <Button
            className={classes.btn}
            radius="xl"
            size={md ? "md" : "lg"}
            variant="gradient"
            gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
            to={"prices"}
            smooth={true}
            offset={offset}
            duration={1000}
            component={Link}
          >
            Начать обучение
          </Button>
        </Flex>
        <Image ratio="590/1192" className={classes.image} src="assets/bot.png"
               sx={{ borderRadius: 20, maxWidth: 248 }}/>
      </Flex>
    </Container>
  )
}
