import { makeAutoObservable } from "mobx"

class FormModalStoreImpl {
  data: {
    target: string | null,
  } = {
    target: null,
  }
  isOpened: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setData(data: {
    target: string | null,
  }) {
    this.data = data

    if (data.target) {
      this.isOpened = true
    }
  }

  setIsOpened(state: boolean) {
    this.isOpened = state

    if (!state) {
      this.setData({
        target: null,
      })
    }
  }
}

export const FormModalStore = new FormModalStoreImpl()
