import "./App.css"
import "@fontsource/mulish/400.css"
import "@fontsource/mulish/500.css"
import "@fontsource/mulish/600.css"
import "@fontsource/mulish/700.css"
import "@fontsource/mulish/900.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import { MantineProvider, MantineTheme } from "@mantine/core"
import React, { Suspense } from "react"
import { LoadingScreen, BackToTop } from "../shared/ui"
import { MainPage } from "../pages"
import { FormModal } from "../components"
import { PrivacyModal } from "../components/privacyModal"

// ----------------------------------------------------------------------

export function App(): React.JSX.Element {
  const theme = {
    fontFamily: "Mulish, sans-serif",
    colorScheme: "light",
    black: "#222222",

    colors: {
      magenta: [ "#EBEBFA", "#C6C6F1", "#A1A1E8", "#7C7CDF", "#5757D6", "#3232CD", "#6464d9", "#2828A4", "#1E1E7B", "#141452" ],
      spruce: [
        "#edfdf5",
        "#daf9eb",
        "#aef4d4",
        "#81eeba",
        "#5eeaa5",
        "#4ae798",
        "#3fe691",
        "#32cc7d",
        "#27b56e",
        "#119d40",
      ],
      dartmouth:
        [
          "#ebfff7",
          "#d6feed",
          "#a6fdd8",
          "#75fdc3",
          "#53fdb1",
          "#43fda5",
          "#3afd9f",
          "#2fe28a",
          "#21c87a",
          "#00ad67",
        ],
    },

    primaryShade: { light: 6, dark: 8 },
    primaryColor: "teal",
    shadows: {
      light: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
    },

    components: {
      Modal: {
        defaultProps: {
          radius: "lg",
        },
      },
    },

    globalStyles: (theme: MantineTheme) => ({
      body: {
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : "#f5f5f5",
      },
    }),
  }

  // @ts-ignore
  return (<MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <Suspense fallback={<LoadingScreen/>}>
        <MainPage/>
        <FormModal/>
        <PrivacyModal/>
      </Suspense>
      <BackToTop/>
    </MantineProvider>
  )
}
