import React from "react"
import { Container, Title, createStyles, getStylesRef, Text, Flex, Box, Group, Button, rem } from "@mantine/core"
import { Image } from "../../shared/ui"
import { Link } from "react-scroll"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  imageBox1: {
    background: theme.fn.linearGradient(90, theme.colors.teal[7], theme.colors.green[7]),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 96,
    minWidth: 96,
    height: 96,
    minHeight: 96,
    borderRadius: theme.radius.sm,

    [theme.fn.smallerThan(425)]: {
      width: 48,
      minWidth: 48,
      height: 48,
      minHeight: 48,
      float: "left",
      marginRight: 16,
    },
  },

  imageBox2: {
    background: theme.fn.linearGradient(90, theme.colors.green[7], theme.colors.teal[7]),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 96,
    minWidth: 96,
    height: 96,
    minHeight: 96,
    borderRadius: theme.radius.sm,

    [theme.fn.smallerThan(425)]: {
      width: 48,
      minWidth: 48,
      height: 48,
      minHeight: 48,
      float: "left",
      marginRight: 16,
      background: theme.fn.linearGradient(90, theme.colors.teal[7], theme.colors.green[7]),
    },
  },


  leftBox: {
    position: "relative",

    "&::before": {
      content: "''",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "99%",
      margin: "auto",
      width: 15,
      height: 28,
      clipPath: "polygon(100% 48%, 0 0, 0 100%)",
      background: theme.fn.linearGradient(90, theme.colors.green[7], theme.colors.green[7]),

      [theme.fn.smallerThan(425)]: {
        width: 10,
        height: 20,
      },
    },
  },

  rightBox: {
    position: "relative",

    "&::before": {
      content: "''",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      margin: "auto",
      width: 15,
      height: 28,
      clipPath: "polygon(0 48%, 100% 100%, 100% 0)",
      right: "99%",
      background: theme.fn.linearGradient(90, theme.colors.green[7], theme.colors.green[7]),

      [theme.fn.smallerThan(425)]: {
        width: 10,
        height: 20,
      },
    },

    [theme.fn.smallerThan(425)]: {
      [`&:not(.${getStylesRef("floatRight")})`]: {
        display: "none",
      },
    },
  },

  highlight: {
    position: "relative",
    color: theme.white,
    background: theme.fn.linearGradient(90, theme.colors.teal[7], theme.colors.green[7]),
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },

  title: {
    marginBottom: 64,
    fontSize: 48,
    lineHeight: 1.6,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  mainFlex: {
    justifyContent: "center",
    gap: 48,

    [theme.fn.smallerThan(1080)]: {
      gap: 24,
    },

    [theme.fn.smallerThan(900)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  block: {
    paddingTop: 48,
    gap: 24,
  },

  upperBlock: {
    paddingTop: 64,

    [theme.fn.smallerThan(1080)]: {
      paddingTop: 0,
    },
  },

  mainImage: {
    [theme.fn.smallerThan(1080)]: {
      display: "none",
    },
  },

  smallList: {
    gap: 48,

    [theme.fn.largerThan(900)]: {
      display: "none",
    },

    [theme.fn.smallerThan(425)]: {
      gap: 24,
    },
  },

  largeBlock: {
    [theme.fn.smallerThan(900)]: {
      display: "none",
    },
  },

  smallBlock: {
    gap: 24,

    [theme.fn.smallerThan(425)]: {
      display: "block",
    },
  },

  blockImage: {
    width: 64,
    height: 64,

    [theme.fn.smallerThan(425)]: {
      width: 32,
      height: 32,
    },
  },

  text: {
    maxWidth: 270,

    [theme.fn.smallerThan(425)]: {
      maxWidth: "unset",
    },
  },

  buttonBox: {
    marginTop: 48,

    [theme.fn.smallerThan(900)]: {
      marginTop: 32,
    },
  },

  floatRight: {
    ref: getStylesRef("floatRight"),

    "&::before": {
      content: "''",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "99%",
      margin: "auto",
      width: 15,
      height: 28,
      clipPath: "polygon(100% 48%, 0 0, 0 100%)",
      background: theme.fn.linearGradient(90, theme.colors.green[7], theme.colors.green[7]),

      [theme.fn.smallerThan(425)]: {
        width: 10,
        height: 20,
      },
    },

    [theme.fn.largerThan(425)]: {
      display: "none",
    },
  },
}))

export function Features(): React.JSX.Element {
  const { cx, classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)

  const offset = md ? -48 : xs ? -36 : -72

  return (
    <Container size={1320} className={classes.wrapper}>
      <Title align="center" className={classes.title}>Это профессиональное обучение <span
        className={classes.highlight}>для вас</span>, если</Title>

      <Flex className={classes.mainFlex}>

        <Flex direction="column" className={classes.smallList}>
          <Flex className={classes.smallBlock}>
            <Box className={cx(classes.imageBox1, classes.leftBox)}>
              <Image className={classes.blockImage} src="assets/medical-result.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Вы задумываетесь о том, чтобы получить качественное
              образование и
              построить
              карьеру в сфере
              нутрициологии.</Text>
          </Flex>
          <Flex className={classes.smallBlock}>
            <Box className={cx(classes.imageBox2, classes.rightBox, classes.floatRight)}>
              <Image className={classes.blockImage} src="assets/microscope.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Придерживаетесь позиций доказательной
              медицины и хотите
              изучать
              нутрициологию с научной точки зрения.</Text>
            <Box className={cx(classes.imageBox2, classes.rightBox)}>
              <Image className={classes.blockImage} src="assets/microscope.png"/>
            </Box>
          </Flex>
          <Flex className={classes.smallBlock}>
            <Box className={cx(classes.imageBox1, classes.leftBox)}>
              <Image className={classes.blockImage} src="assets/balanced-diet.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Являетесь специалистом в сфере фитнеса и нутрициологии и
              хотите повысить
              свою
              квалификацию.</Text>
          </Flex>
          <Flex className={classes.smallBlock}>
            <Box className={cx(classes.imageBox2, classes.rightBox, classes.floatRight)}>
              <Image className={classes.blockImage} src="assets/pharmacy.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Работаете в сфере медицины и хотите
              получить образование в
              нутрициологии для повышения
              уровня квалификации.</Text>
            <Box className={cx(classes.imageBox2, classes.rightBox)}>
              <Image className={classes.blockImage} src="assets/pharmacy.png"/>
            </Box>
          </Flex>
          <Flex className={classes.smallBlock}>
            <Box className={cx(classes.imageBox1, classes.leftBox)}>
              <Image className={classes.blockImage} src="assets/nutrition.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Хотите помогать людям выстраивать здоровый
              сбалансированный рацион и
              зарабатывать
              на любимом
              деле.</Text>
          </Flex>
          <Flex className={classes.smallBlock}>
            <Box className={cx(classes.imageBox2, classes.rightBox, classes.floatRight)}>
              <Image className={classes.blockImage} src="assets/functional-foods.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Вы устали от учебных курсов с антинаучной
              направленностью
              и хотите
              получить образование
              в соответствии с международными стандартами.</Text>
            <Box className={cx(classes.imageBox2, classes.rightBox)}>
              <Image className={classes.blockImage} src="assets/functional-foods.png"/>
            </Box>
          </Flex>
        </Flex>

        <Flex className={classes.largeBlock} direction="column">
          <Flex className={cx(classes.block, classes.upperBlock)}>
            <Box className={cx(classes.imageBox1, classes.leftBox)}>
              <Image className={classes.blockImage} src="assets/medical-result.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Вы задумываетесь о том, чтобы получить качественное
              образование и
              построить
              карьеру в сфере
              нутрициологии.</Text>
          </Flex>
          <Flex className={classes.block}>
            <Box className={cx(classes.imageBox1, classes.leftBox)}>
              <Image className={classes.blockImage} src="assets/balanced-diet.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Являетесь специалистом в сфере фитнеса и нутрициологии и
              хотите повысить
              свою
              квалификацию.</Text>
          </Flex>
          <Flex className={classes.block}>
            <Box className={cx(classes.imageBox1, classes.leftBox)}>
              <Image className={classes.blockImage} src="assets/nutrition.png"/>
            </Box>
            <Text className={classes.text} fw={500} c="dimmed">Хотите помогать людям выстраивать здоровый
              сбалансированный рацион и
              зарабатывать
              на любимом
              деле.</Text>
          </Flex>
        </Flex>

        <Image
          className={classes.mainImage}
          sx={{ borderRadius: 8, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          ratio="3/4"
          maw={420}
          src="assets/derick-mckinney-__QqvTI5Edc-unsplash.jpg"
        />

        <Flex className={classes.largeBlock} direction="column">
          <Flex className={cx(classes.block, classes.upperBlock)}>
            <Text className={classes.text} fw={500} c="dimmed" align="right">Придерживаетесь позиций доказательной
              медицины и хотите
              изучать
              нутрициологию с научной точки зрения.</Text>
            <Box className={cx(classes.imageBox2, classes.rightBox)}>
              <Image className={classes.blockImage} src="assets/microscope.png"/>
            </Box>
          </Flex>
          <Flex className={classes.block}>
            <Text className={classes.text} fw={500} c="dimmed" align="right">Работаете в сфере медицины и хотите
              получить образование в
              нутрициологии для повышения
              уровня квалификации.</Text>
            <Box className={cx(classes.imageBox2, classes.rightBox)}>
              <Image className={classes.blockImage} src="assets/pharmacy.png"/>
            </Box>
          </Flex>
          <Flex className={classes.block}>
            <Text className={classes.text} fw={500} c="dimmed" align="right">Вы устали от учебных курсов с антинаучной
              направленностью
              и хотите
              получить образование
              в соответствии с международными стандартами.</Text>
            <Box className={cx(classes.imageBox2, classes.rightBox)}>
              <Image className={classes.blockImage} src="assets/functional-foods.png"/>
            </Box>
          </Flex>
        </Flex>

      </Flex>

      <Group className={classes.buttonBox} position="center">
        <Flex align="center" justify="center" direction="column">
          <Button
            variant="gradient"
            gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
            size={md ? "md" : "lg"}
            radius="xl"
            to={"prices"}
            smooth={true}
            offset={offset}
            duration={1000}
            component={Link}
          >
            Начать обучение
          </Button>
        </Flex>
      </Group>

    </Container>
  )
}
