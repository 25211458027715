import { createStyles, Paper, Text, rem, ActionIcon, Flex, Modal, Box } from "@mantine/core"
import { IconPlayerPlayFilled } from "@tabler/icons-react"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import React from "react"

const useStyles = createStyles((theme) => ({
  card: {
    height: rem(426),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    zIndex: 0,
    overflow: "hidden",
    cursor: "pointer",

    [theme.fn.smallerThan("md")]: {
      minWidth: rem(268),
    },
  },

  title: {
    fontWeight: 700,
    color: theme.white,
    zIndex: 2,
  },

  category: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    zIndex: 2,
  },

  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to bottom, rgba(0,0,0,0.6), transparent 20%, transparent 70%, rgba(0,0,0,0.6))",
    zIndex: 1,
  },

  modalContent: {
    background: "transparent",
    overflow: "unset",
  },

  modalBody: {
    padding: 0,
    position: "relative",
    borderRadius: theme.radius.lg,
    overflow: "hidden",
  },

  videoContainer: {
    height: "100vh",
    width: "50vh",
    maxHeight: "80vh",
    position: "relative",
    // backgroundColor: "black",

    [theme.fn.smallerThan("xs")]: {
      width: "calc(100vw - 32px)",
      height: "calc((100vw - 32px) * 18 / 9)",
    },
  },

  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  close: {
    position: "absolute",
    top: -48,
    right: -48,
    color: "white",
    backgroundColor: "rgba(255, 255, 255, 0.2)",

    "&:hover": {
      color: "white",
      backgroundColor: "rgba(255, 255, 255, 0.4)",
    },

    [theme.fn.smallerThan("sm")]: {
      top: -56,
      right: 0,
    },
  },
}))

interface ArticleCardImageProps {
  image: string
  title: string
  category: string
  id: string
}

export function ReviewVideoCard({ image, title, category, id }: ArticleCardImageProps): React.JSX.Element {
  const { classes } = useStyles()
  const [ opened, { open, close } ] = useDisclosure(false)
  const sm = useMediaQuery("(max-width: 768px)")

  return (
    <>
      <Modal.Root zIndex={8000} opened={opened} onClose={close} size="auto" centered>
        <Modal.Overlay opacity={0.85}/>
        <Modal.Content className={classes.modalContent}>
          <Modal.CloseButton
            onClick={close}
            className={classes.close}
            aria-label="Закрыть модальное окно"
            size={sm ? 40 : 48}
            radius="xl"
            iconSize={sm ? 32 : 48}
          />
          <Modal.Body className={classes.modalBody}>
            <Box className={classes.videoContainer}>
              <video controls poster={image} playsInline className={classes.video}>
                <source src={`https://api.bodycoach.pro/public/review/${id}.mp4`} type="video/mp4"/>
              </video>
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <Paper p="xl" radius="xl" sx={{ backgroundImage: `url(${image})` }} className={classes.card}>
        <Flex align="center" justify="center" className={classes.container}>
          <ActionIcon
            gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
            variant="gradient"
            size={56}
            radius={28}
            onClick={open}>
            <IconPlayerPlayFilled size={20}/>
          </ActionIcon>
        </Flex>
        <Text className={classes.category} size="sm">
          {category}
        </Text>
        <Text className={classes.title} size="xl">
          {title}
        </Text>
      </Paper>
    </>
  )
}
