import {
  Anchor,
  Container,
  createStyles,
  List,
  Modal,
  Stack,
  Text,
} from "@mantine/core"
import { observer } from "mobx-react-lite"
import { PrivacyModalStore } from "../../app/PrivacyModalStore"

const useStyles = createStyles((theme) => ({
  modalContent: {
    width: "100%",
    maxWidth: 768,
  },

  modalHeader: {
    padding: "32px 32px 24px",

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalBody: {
    padding: 32,

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalTitle: {
    fontWeight: 900,
    fontSize: 24,

    [theme.fn.smallerThan(425)]: {
      fontWeight: 700,
      fontSize: 20,
    },
  },
}))

const BodyCoachLink = () => {
  return (
    <Anchor href="https://bodycoach.pro/" target="_blank" color="orange">
      bodycoach.pro
    </Anchor>
  )
}

export const PrivacyModal = observer(function PrivacyModal(): React.JSX.Element {
    const { classes } = useStyles()
    const close = () => PrivacyModalStore.setIsOpened(false)

    return (
      <Modal.Root
        opened={PrivacyModalStore.isOpened}
        onClose={close}
        zIndex={5000}
        fullScreen
      >
        <Modal.Overlay/>
        <Modal.Content className={classes.modalContent}>
          <Modal.Header className={classes.modalHeader}>
            <Modal.Title className={classes.modalTitle}>Политика конфиденциальности</Modal.Title>
            <Modal.CloseButton radius="xl" size="lg"/>
          </Modal.Header>
          <Modal.Body className={classes.modalBody}>

            <Container size={1320} sx={{ overflow: "hidden" }}>
              <Stack spacing="xl">
                <Text>
                  Политика конфиденциальности Настоящая Политика конфиденциальности персональной информации (далее —
                  Политика)
                  действует в отношении всей информации, которую ШКОЛА ФИТНЕСА «BODY COACH» (ОГРНИП: 318237500349221,ИНН:
                  237306774288 , адрес регистрации: 35016, КРАСНОДАРСКИЙ КРАЙ, Г. КРАСНОДАР, УЛ. КАРЯКИНА, Д. 18/, ОФ. 44)
                  и/или
                  его аффилированные лица, могут получить о пользователе во время использования им сайта
                  {" "}<BodyCoachLink/>.
                </Text>

                <Text>
                  Использование сайта {" "}<BodyCoachLink/> означает безоговорочное согласие пользователя с настоящей
                  Политикой
                  и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими
                  условиями
                  пользователь должен воздержаться от использования данного ресурса.
                </Text>

                <Text>
                  <Text span fw={700}>1.</Text> Персональная информация пользователей, которую получает и обрабатывает
                  сайт
                  {" "}<BodyCoachLink/>.
                </Text>

                <Text>
                  <Text span fw={700}>1.1.</Text> В рамках настоящей Политики под «персональной информацией пользователя»
                  понимаются:
                </Text>

                <Text>
                  <Text span fw={700}>1.1.1.</Text> Персональная информация, которую пользователь предоставляет о себе
                  самостоятельно при оставлении
                  заявки, совершении покупки, регистрации (создании учётной записи) или в ином процессе использования
                  сайта.
                </Text>

                <Text>
                  <Text span fw={700}>1.1.2.</Text> Данные, которые автоматически передаются
                  сайтом {" "}<BodyCoachLink/> в
                  процессе его использования с
                  помощью установленного на устройстве пользователя программного обеспечения, в том числе IP-адрес,
                  информация
                  из cookie, информация о браузере пользователя (или иной программе, с помощью которой осуществляется
                  доступ
                  к
                  сайту), время доступа, адрес запрашиваемой страницы.
                </Text>

                <Text>
                  <Text span fw={700}>1.1.3.</Text> Данные, которые предоставляются сайту, в целях осуществления оказания
                  услуг и/или продаже товара
                  и/или предоставления иных ценностей для посетителей сайта, в соответствии с деятельностью настоящего
                  ресурса:
                </Text>

                <List withPadding>
                  <List.Item>фамилия</List.Item>
                  <List.Item>имя</List.Item>
                  <List.Item>электронная почта</List.Item>
                  <List.Item>номер телефона</List.Item>
                  <List.Item>ссылка на персональный сайт или соцсети</List.Item>
                  <List.Item>ip адрес</List.Item>
                </List>

                <Text>
                  <Text span fw={700}>1.2.</Text> Настоящая Политика применима только к сайту {" "}<BodyCoachLink/> и не
                  контролирует и не несет
                  ответственность за сайты третьих лиц, на которые пользователь может перейти по ссылкам, доступным на
                  сайте
                  {" "}<BodyCoachLink/>. На таких сайтах у пользователя может собираться или запрашиваться иная
                  персональная
                  информация, а также могут совершаться иные действия.
                </Text>

                <Text>
                  <Text span fw={700}>1.3.</Text> Сайт в общем случае не проверяет достоверность персональной информации,
                  предоставляемой пользователями,
                  и не осуществляет контроль за их дееспособностью. Однако сайт {" "}<BodyCoachLink/> исходит из того, что
                  пользователь предоставляет достоверную и достаточную персональную информацию по вопросам, предлагаемым в
                  формах настоящего ресурса, и поддерживает эту информацию в актуальном состоянии.
                </Text>

                <Text>
                  <Text span fw={700}>2.</Text> Цели сбора и обработки персональной информации пользователей
                </Text>

                <Text>
                  <Text span fw={700}>2.1.</Text> Сайт собирает и хранит только те персональные данные, которые необходимы
                  для
                  оказания услуг и/или
                  продаже товара и/или предоставления иных ценностей для посетителей сайта {" "}<BodyCoachLink/>.
                </Text>

                <Text>
                  <Text span fw={700}>2.2.</Text> Персональную информацию пользователя можно использовать в следующих
                  целях:
                </Text>

                <Text>
                  <Text span fw={700}>2.2.1.</Text> Предоставление пользователю персонализированных услуг и сервисов,
                  товаров
                  и иных ценностей
                </Text>

                <Text>
                  <Text span fw={700}>2.2.2.</Text> Связь с пользователем, в том числе направление уведомлений, запросов и
                  информации, касающихся
                  использования сайта, оказания услуг, а также обработка запросов и заявок от пользователя
                </Text>

                <Text>
                  <Text span fw={700}>2.2.3.</Text> Таргетирование рекламных материалов
                </Text>

                <Text>
                  <Text span fw={700}>2.2.4.</Text> Проведение статистических и иных исследований на основе
                  предоставленных
                  данных 3. Условия обработки
                  персональной информации пользователя и её передачи третьим лицам
                </Text>

                <Text>
                  <Text span fw={700}>3.1.</Text> Сайт {" "}<BodyCoachLink/> хранит персональную информацию пользователей
                  в
                  соответствии с внутренними
                  регламентами конкретных сервисов.
                </Text>

                <Text>
                  <Text span fw={700}>3.2.</Text> В отношении персональной информации пользователя сохраняется ее
                  конфиденциальность, кроме случаев
                  добровольного предоставления пользователем информации о себе для общего доступа неограниченному кругу
                  лиц.
                </Text>

                <Text>
                  <Text span fw={700}>3.3.</Text> Сайт {" "}<BodyCoachLink/> вправе передать персональную информацию
                  пользователя третьим лицам в
                  следующих случаях:
                </Text>

                <Text>
                  <Text span fw={700}>3.3.1.</Text> Пользователь выразил свое согласие на такие действия, путем согласия,
                  выразившегося в предоставлении
                  таких данных;
                </Text>

                <Text>
                  <Text span fw={700}>3.4.</Text> Передача необходима в рамках использования пользователем определенного
                  сайта
                  {" "}<BodyCoachLink/>,
                  либо для предоставления товаров и/или оказания услуги пользователю;
                </Text>

                <Text>
                  <Text span fw={700}>3.3.2.</Text> Передача предусмотрена российским или иным применимым
                  законодательством
                  в
                  рамках установленной
                  законодательством процедуры;
                </Text>

                <Text>
                  <Text span fw={700}>3.3.3.</Text> В целях обеспечения возможности защиты прав и законных интересов сайта
                  {" "}<BodyCoachLink/> или
                  третьих лиц в случаях, когда пользователь нарушает Пользовательское соглашение
                  сайта {" "}<BodyCoachLink/>.
                </Text>

                <Text>
                  <Text span fw={700}>3.4.</Text> При обработке персональных данных пользователей сайт
                  {" "}<BodyCoachLink/> руководствуется Федеральным
                  законом РФ «О персональных данных».
                </Text>

                <Text>
                  <Text span fw={700}>4.</Text> Изменение пользователем персональной информации
                </Text>

                <Text>
                  <Text span fw={700}>4.1.</Text> Пользователь может в любой момент изменить (обновить, дополнить)
                  предоставленную им персональную
                  информацию или её часть, а также параметры её конфиденциальности, оставив заявление в адрес
                  администрации
                  сайта следующим способом: форма обратной связи: {" "}<BodyCoachLink/>.
                </Text>

                <Text>
                  <Text span fw={700}>4.2.</Text> Пользователь может в любой момент, отозвать свое согласие на обработку
                  персональных данных, оставив
                  заявление в адрес администрации сайта следующим способом: форма обратной связи: {" "}<BodyCoachLink/>.
                </Text>

                <Text>
                  <Text span fw={700}>5.</Text> Меры, применяемые для защиты персональной информации пользователей Сайт
                  принимает необходимые и
                  достаточные организационные и технические меры для защиты персональной информации пользователя от
                  неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования,
                  распространения,
                  а
                  также от иных неправомерных действий с ней третьих лиц.
                </Text>

                <Text>
                  <Text span fw={700}>6.</Text> Изменение Политики конфиденциальности. Применимое законодательство
                </Text>

                <Text>
                  <Text span fw={700}>6.1.</Text> Сайт имеет право вносить изменения в настоящую Политику
                  конфиденциальности.
                  При внесении изменений в
                  актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с
                  момента ее размещения, если иное не предусмотрено новой редакцией Политики.
                </Text>

                <Text>
                  <Text span fw={700}>6.2.</Text> К настоящей Политике и отношениям между пользователем и Сайтом,
                  возникающим
                  в связи с применением
                  Политики конфиденциальности, подлежит применению право Российской Федерации.
                </Text>

                <Text>
                  <Text span fw={700}>7.</Text> Обратная связь. Вопросы и предложения
                </Text>

                <Text>
                  <Text span fw={700}>7.1.</Text> Все предложения или вопросы по поводу настоящей «Политики» следует
                  направлять следующим способом: форма
                  обратной связи: {" "}<BodyCoachLink/>.
                </Text>

              </Stack>

            </Container>

          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    )
  },
)
