import { makeAutoObservable } from "mobx"

class PrivacyModalStoreImpl {
  isOpened: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsOpened(state: boolean) {
    this.isOpened = state
  }
}

export const PrivacyModalStore = new PrivacyModalStoreImpl()
