import React from "react"
import {
  Container,
  createStyles,
  Title,
  Card,
  SimpleGrid,
  Text, Group, Flex, Button,
} from "@mantine/core"
import {
  IconWritingSign,
  IconUsers,
  IconBrandHipchat,
  IconKey,
  IconMovie,
  IconCertificate,
} from "@tabler/icons-react"
import { Link } from "react-scroll"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  card: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: "transparent",
    padding: 0,

    [theme.fn.smallerThan(768)]: {
      "svg": {
        marginLeft: 24,
      },
    },
  },

  title: {
    marginBottom: 64,
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  elTitle: {
    marginTop: 24,
    fontSize: 24,
    position: "relative",
    paddingBottom: 24,
    marginBottom: 24,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 140,
      height: 1,
      background: theme.colors.gray[3],
      bottom: 0,
      left: 0,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 20,
      marginBottom: 16,
      paddingBottom: 16,
      marginTop: 16,
    },
  },

  buttonBox: {
    marginTop: 48,

    [theme.fn.smallerThan(900)]: {
      marginTop: 32,
    },
  },
}))

const data = [
  {
    Icon: IconMovie,
    title: "Лекции и задания",
    text: "Более 100 структурированных уроков с презентациями по каждой теме и задания на закрепление пройденного материала.",
  },
  {
    Icon: IconWritingSign,
    title: "Из теории в практику",
    text: "Получение практических навыков и оформление их в кейсы, практические занятия в группе, разбор ситуационных задач в учебном чате.",
  },
  {
    Icon: IconUsers,
    title: "Поддержка куратора",
    text: "Со студентами работают кураторы, которые помогают разобраться в учебном процессе, проверяют задания, проводят разбор задач и отвечают на вопросы.",
  },
  {
    Icon: IconKey,
    title: "Бессрочный доступ к материалам",
    text: "Все учебные материалы размещены на образовательной платформе, вы получаете бессрочный  доступ к лекциям и дополнительным материалам.",
  },
  {
    Icon: IconBrandHipchat,
    title: "Чат студентов и преподавателей",
    text: "Образовательный процесс проходит с учебным чатом для тематического общения и обсуждения материалов обучения.",
  },
  {
    Icon: IconCertificate,
    title: "Промежуточные тесты и итоговый экзамен",
    text: "В процессе обучения вам предстоит пройти несколько тестирований для закрепления материала и сдать итоговый экзамен для получения образовательных документов.",
  },
]

export function Subjects(): React.JSX.Element {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)
  const offset = md ? -48 : xs ? -36 : -72

  return (
    <section className={classes.wrapper}>
      <Container size={1320}>
        <Title className={classes.title} order={2} align="center">
          <Text
            span
            variant="gradient"
            gradient={{
              from: "teal.7",
              to: "green.7",
              deg: 105,
            }}
          >Как проходит</Text> обучение?</Title>

        <SimpleGrid
          cols={3}
          spacing={48}
          breakpoints={[
            { maxWidth: "sm", cols: 2, spacing: 32, verticalSpacing: 32 },
            { maxWidth: "xs", cols: 1, spacing: 24, verticalSpacing: 24 },
          ]}
        >
          {data.map(({ title, text, Icon }, index) => (
            <Card p={0} key={index} className={classes.card}>
              <Icon size={48}/>

              <Title order={3} className={classes.elTitle}>
                {title}
              </Title>

              <Text c="dimmed">
                {text}
              </Text>
            </Card>
          ))}
        </SimpleGrid>

        <Group className={classes.buttonBox} position="center">
          <Flex align="center" justify="center" direction="column">
            <Button
              variant="gradient"
              gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
              size={md ? "md" : "lg"}
              radius="xl"
              to={"prices"}
              smooth={true}
              duration={1000}
              component={Link}
              offset={offset}
            >
              Начать обучение
            </Button>
          </Flex>
        </Group>

      </Container>
    </section>
  )
}
