import {
  Container,
  createStyles,
  Text,
  SimpleGrid,
  Flex,
  Box,
  ThemeIcon,
  Center,
} from "@mantine/core"
import React from "react"
import { IconChartArrowsVertical, IconCertificate, IconMedicalCrossOff, IconZoomCheck } from "@tabler/icons-react"

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    top: "-50%",
    transform: "translateY(-50%)",
    backgroundColor: theme.white,
    boxShadow: theme.shadows.light,
    color: theme.black,
    borderRadius: 20,
    padding: 16,

    [theme.fn.smallerThan("md")]: {
      marginTop: `calc(${theme.spacing.xl} * 2)`,
      marginBottom: `calc(${theme.spacing.xl} * 2)`,
      top: 0,
      transform: "translateY(0)",
    },

    [theme.fn.smallerThan("xs")]: {
      marginTop: `calc(${theme.spacing.xl} * 1.5)`,
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  text: {
    maxWidth: 160,

    [theme.fn.smallerThan("md")]: {
      maxWidth: 220,
      fontSize: theme.fontSizes.md,
    },

    [theme.fn.smallerThan("xs")]: {
      maxWidth: "unset",
    },
  },
}))

export function About(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <Container size={1320}>
      <Center>
        <Box maw={1024} className={classes.wrapper}>
          <SimpleGrid
            cols={4}
            spacing={24}
            breakpoints={[
              { maxWidth: "lg", cols: 4 },
              { maxWidth: "md", cols: 2 },
              { maxWidth: "xs", cols: 1 },
            ]}
          >
            <Flex align="center" gap="sm">
              <ThemeIcon
                variant="outline"
                size={44}
                radius="xl"
              >
                <IconChartArrowsVertical/>
              </ThemeIcon>
              <Text className={classes.text}>С нуля до профессионала</Text>
            </Flex>
            <Flex align="center" gap="sm">
              <ThemeIcon
                variant="outline"
                size={44}
                radius="xl"
              >
                <IconCertificate/>
              </ThemeIcon>
              <Text className={classes.text}>Документы установленного образца</Text>
            </Flex>
            <Flex align="center" gap="sm">
              <ThemeIcon
                variant="outline"
                size={44}
                radius="xl"
              >
                <IconMedicalCrossOff/>
              </ThemeIcon>
              <Text className={classes.text}>Мед. образование не требуется</Text>
            </Flex>
            <Flex align="center" gap="sm">
              <ThemeIcon
                variant="outline"
                size={44}
                radius="xl"
              >
                <IconZoomCheck/>
              </ThemeIcon>
              <Text className={classes.text}>Только доказательный подход</Text>
            </Flex>
          </SimpleGrid>
        </Box>
      </Center>
    </Container>

  )
}
