import { Text, Container, Center, createStyles, Title, Tabs, Group, Flex, Button } from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { FormModalStore } from "../../app/FormModalStore"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 64,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
    },

    [theme.fn.smallerThan("xs")]: {
      marginBottom: 24,
    },
  },

  titleOuter: {
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
    },
  },

  innerTitle: {
    fontSize: 16,
    marginTop: 16,
  },

  image: {
    marginTop: 24,
    flex: 1,
    width: "100%",
    maxWidth: 480,
  },

  buttonBox: {
    marginTop: 48,

    [theme.fn.smallerThan(900)]: {
      marginTop: 32,
    },
  },
}))

export function Docs(): React.JSX.Element {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)

  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <Center className={classes.title}>
          <Title className={classes.titleOuter} order={2} align="center">
            <Text
              span
              variant="gradient"
              gradient={{
                from: "teal.7",
                to: "green.7",
                deg: 105,
              }}>
              Документы
            </Text>&nbsp;
            после прохождения обучения
          </Title>
          <Text align="center" className={classes.innerTitle} c="dimmed">Сертификат, и удостоверение в твердом переплете
            с вкладышем</Text>
        </Center>

        <Center>
          <Tabs color="teal.7" variant="pills" defaultValue="solid">
            <Tabs.List grow>
              <Tabs.Tab value="solid">Твердый переплет</Tabs.Tab>
              <Tabs.Tab value="permit">Удостоверение</Tabs.Tab>
              <Tabs.Tab value="certificate">Сертификат</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="solid" pt="xs">
              <Image
                objectFit="contain"
                sx={{ borderRadius: 4 }}
                src="assets/docs/solid_example.png"
                className={classes.image}
              />
            </Tabs.Panel>

            <Tabs.Panel value="permit" pt="xs">
              <Image
                objectFit="contain"
                sx={{ borderRadius: 4 }}
                src="assets/docs/permit_example.jpg"
                className={classes.image}
              />
            </Tabs.Panel>

            <Tabs.Panel value="certificate" pt="xs">
              <Image
                objectFit="contain"
                sx={{ borderRadius: 4 }}
                src="assets/docs/certificate_example.jpg"
                className={classes.image}
              />
            </Tabs.Panel>
          </Tabs>
        </Center>

        <Group className={classes.buttonBox} position="center">
          <Flex align="center" justify="center" direction="column">
            <Button
              variant="gradient"
              gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
              size={md ? "md" : "lg"}
              radius="xl"
              onClick={() => FormModalStore.setData({ target: "Профессия: Нутрициолог. Блок: Документы. Кнопка: Получить консультацию" })}
            >
              Получить консультацию
            </Button>
          </Flex>
        </Group>

      </Container>
    </div>

  )
}
