import { Carousel } from "@mantine/carousel"
import { Container, Title, createStyles, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { ReviewVideoCard } from "./ReviewVideoCard"
import { caruselItems } from "./config"
import React from "react"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  outer: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
      paddingRight: 0,
      paddingLeft: "1rem",
    },
  },

  title: {
    marginBottom: 64,
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  carusel: {
      ".mantine-Carousel-controls": {
        ".mantine-Carousel-control": {
          boxShadow: "none",
          borderColor: "#fff",
          color: "#5757D6",
          background: "#fff",
        },
      },
    },
}))

export function ReviewCarusel(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`)

  return (
    <Container size={1320} className={classes.outer}>
      <Title align="center" className={classes.title}>Отзывы о&nbsp;
        <Text
          span
          variant="gradient"
          gradient={{ from: "teal.7", to: "green.7", deg: 105 }}>
          BodyCoach
        </Text>
      </Title>
      <Carousel
        className={classes.carusel}
        withControls={matches}
        height={426}
        slideSize="25%"
        slideGap="xl"
        loop
        align="start"
        controlSize={40}
        breakpoints={[ { maxWidth: "lg", slideSize: "33.333%", slideGap: "xs" } ]}
        nextControlIcon={<IconArrowRight/>}
        previousControlIcon={<IconArrowLeft/>}
      >
        {caruselItems.map((item) => (
          <Carousel.Slide key={item.id}>
            <ReviewVideoCard {...item} />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Container>
  )
}
