import {
  Text,
  Container,
  Title,
  createStyles,
  Flex,
  Box,
  SimpleGrid,
} from "@mantine/core"
import React from "react"

const data = [
  {
    text: "Часовая онлайн-консультация",
    boxes: [
      {
        salary: `от${"\u00A0"}3${"\u00A0"}000`,
        subtitle: "в Москве",
        accent: false,
      },
      {
        salary: `от${"\u00A0"}2${"\u00A0"}000`,
        subtitle: "в регионах",
        accent: false,
      },
    ],
  },
  {
    text: "Подготовка программы питания",
    boxes: [
      {
        salary: `от${"\u00A0"}10${"\u00A0"}000`,
        subtitle: "в Москве",
        accent: false,
      },
      {
        salary: `от${"\u00A0"}5${"\u00A0"}000`,
        subtitle: "в регионах",
        accent: false,
      },
    ],
  },
  {
    text: "Запуск своего проекта в формате \"Марафон по похудению\"",
    boxes: [
      {
        salary: `от${"\u00A0"}100${"\u00A0"}000 до${"\u00A0"}10${"\u00A0"}000${"\u00A0"}000`,
        subtitle: "в Москве и регионах",
        accent: true,
      },
    ],
  },
]

const useStyles = createStyles((theme) => ({
  inner: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    marginBottom: 64,
    fontSize: 48,
    lineHeight: 1.6,

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  flex: {
    gap: 64,

    [theme.fn.smallerThan("lg")]: {
      gap: 32,
    },

    [theme.fn.smallerThan("md")]: {
      gap: 24,
    },
  },

  container: {
    width: "100%",
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    paddingBottom: 64,

    [theme.fn.smallerThan("lg")]: {
      paddingBottom: 32,
    },

    [theme.fn.smallerThan("md")]: {
      paddingBottom: 24,
    },
  },

  text: {
    marginTop: 12,
    fontSize: 32,
    fontWeight: 700,

    [theme.fn.smallerThan("lg")]: {
      fontSize: 28,
    },

    [theme.fn.smallerThan("md")]: {
      fontSize: 20,
    },
  },

  boxContainer: {
    gap: 24,

    [theme.fn.smallerThan("md")]: {
      gap: 12,
    },
  },

  box: {
    width: "calc(50% - 12px)",
    padding: "12px 24px",
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.md,

    [theme.fn.smallerThan("lg")]: {
      width: "calc(50% - 8px)",
      padding: "8px 16px",
    },

    [theme.fn.smallerThan("md")]: {
      width: "calc(50% - 6px)",
      padding: "6px 12px",
    },
  },

  accentBox: {
    width: "100%",
    backgroundColor: theme.colors.green[3],
    borderColor: theme.colors.green[4],

    [theme.fn.smallerThan("lg")]: {
      width: "100%",
    },

    [theme.fn.smallerThan("md")]: {
      width: "100%",
    },
  },

  salary: {
    fontSize: 32,
    fontWeight: 900,

    [theme.fn.smallerThan("lg")]: {
      fontSize: 28,
    },

    [theme.fn.smallerThan("md")]: {
      fontSize: 20,
    },
  },

  subtitle: {
    fontSize: 16,
  },
}))

function Row(element: typeof data[0]) {
  const { classes, cx } = useStyles()

  return (
    <SimpleGrid
      cols={2}
      breakpoints={[
        { maxWidth: "md", cols: 1 },
      ]}
      className={classes.container}
    >
      <Box>
        <Text className={classes.text}>{element.text}</Text>
      </Box>
      <Flex className={classes.boxContainer}>
        {element.boxes.map((el, index) => (
          <Box key={index} className={cx(classes.box, { [classes.accentBox]: el.accent })}>
            <Text className={classes.salary}>{el.salary}&nbsp;&#8381;</Text>
            <Text className={classes.subtitle}>{el.subtitle}</Text>
          </Box>
        ))}
      </Flex>
    </SimpleGrid>
  )
}

export function Salary(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <Container size="xl" className={classes.inner}>
      <Title className={classes.title} maw={768}>
        Сколько зарабатывает специалист
      </Title>

      <Flex direction="column" className={classes.flex}>
        {data.map((row, index) => (
          <Row key={index} {...row}/>
        ))}
      </Flex>

    </Container>
  )
}
