import { createStyles, Title, rem, List, ThemeIcon, Container, Group, Text, Button } from "@mantine/core"
import React from "react"
import { useMediaQuery } from "@mantine/hooks"
import { Link } from "react-scroll"
import { IconRocket, IconCalendar, IconStarFilled } from "@tabler/icons-react"
import { Image } from "../../shared/ui"
import theme from "tailwindcss/defaultTheme"

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    background: "#eeebe3",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,

    "&::before": {
      content: "''",
      backgroundImage: "url(assets/team-back.png)",
      backgroundAttachment: "fixed",
      backgroundPosition: "top center",
      backgroundSize: "cover",
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
      opacity: 0.3,
    },
  },

  container: {
    paddingTop: 90,
    maxWidth: 1320,
  },

  inner: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  content: {
    maxWidth: "45%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  uptitle: {
    width: "fit-content",
    marginTop: rem(24),

    padding: "6px 12px",
    backgroundColor: theme.fn.rgba(theme.colors.orange[6], 0.1),
    borderRadius: theme.radius.sm,

    [theme.fn.smallerThan("md")]: {
      margin: "24px auto 0",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontSize: rem(54),
    lineHeight: 1.5,
    fontWeight: 900,

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(24),
      lineHeight: 1.7,
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  subTitle: {
    marginTop: 24,

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
      fontSize: 20,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 18,
    },
  },

  image: {
    flex: 1,
    maxWidth: "45%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  highlight: {
    position: "relative",
    color: theme.white,
    background: theme.fn.linearGradient(90, theme.colors.teal[7], theme.colors.green[7]),
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },

  highlight2: {
    position: "relative",
    color: theme.black,
    backgroundColor: theme.colors.orange[5],
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },

  list: {
    marginTop: 32,
    paddingLeft: theme.spacing.xl,

    [theme.fn.smallerThan("md")]: {
      paddingLeft: 0,
      justifyContent: "center",
      marginTop: 24,
    },
  },

  btnGroup: {
    marginTop: 48,

    [theme.fn.smallerThan("md")]: {
      justifyContent: "center",
      marginTop: 24,
    },
  },
}))

export function Hero(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const xs = useMediaQuery(`(max-width: 576px)`)

  const offset = md ? -48 : xs ? -36 : -72

  return (
    <section id="main" className={classes.wrapper}>
      <Container px="md" className={classes.container}>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Group align="center" spacing={8} className={classes.uptitle}>
              <IconStarFilled size={16} style={{ color: theme.colors.orange[5] }}/>
              <Text fw={900} fz={14}>Полностью обновлен 01.08.2024</Text>
            </Group>
            <Title className={classes.title}>
              Нутрициолог<br/><span className={classes.highlight}>Научный</span> подход
            </Title>
            <Text className={classes.subTitle} size={24} color="dimmed">
              Освойте востребованную профессию
            </Text>

            <Group className={classes.list}>
              <List size={xs ? "sm" : "md"} center spacing={24}>
                <List.Item
                  icon={
                    <ThemeIcon
                      size={xs ? 28 : 36}
                      radius="xl"
                      variant="gradient"
                      gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
                    >
                      <IconCalendar size={xs ? 20 : 24}/>
                    </ThemeIcon>
                  }
                >
                  <Text size={xs ? "sm" : "lg"} fw={700}>
                    Старт обучения&nbsp;&nbsp;–&nbsp;&nbsp;
                    14&nbsp;сентября
                  </Text>
                </List.Item>
                <List.Item
                  icon={
                    <ThemeIcon
                      size={xs ? 28 : 36}
                      radius="xl"
                      variant="gradient"
                      gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
                    >
                      <IconRocket size={xs ? 20 : 24}/>
                    </ThemeIcon>
                  }
                >
                  <Text size={xs ? "sm" : "lg"} fw={700}>
                    Продолжительность&nbsp;&nbsp;–&nbsp;&nbsp;
                    6&nbsp;месяцев
                  </Text>
                </List.Item>
              </List>
            </Group>

            <Group className={classes.btnGroup}>
              <Button
                radius="xl"
                size={md ? "md" : "lg"}
                className={classes.control}
                variant="gradient"
                gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
                to={"prices"}
                smooth={true}
                duration={1000}
                component={Link}
                offset={offset}
              >
                Начать обучение
              </Button>
            </Group>
          </div>
          <Image objectFit="contain" sx={{ borderRadius: 20 }} src="assets/hero-image.svg"
                 className={classes.image}/>
        </div>
      </Container>
    </section>
  )
}
