import { Timeline, Text, Container, Center, createStyles, Title, Flex, Box, Button } from "@mantine/core"
import {
  IconNorthStar,
  IconChartBar,
  IconLungs,
  IconShieldLock,
  IconCherry,
  IconVaccineBottle,
  IconCarrot,
  IconFlask,
  IconPhysotherapist,
  IconUsers,
  IconStarHalfFilled,
} from "@tabler/icons-react"
import React from "react"
import { Image } from "../../shared/ui"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    backgroundImage: "repeating-linear-gradient(150deg,rgba(0,0,0,.02),rgba(0,0,0,.02) 1px,transparent 0,transparent 41px),repeating-linear-gradient(-150deg,rgba(0,0,0,.02),rgba(0,0,0,.02) 1px,transparent 0,transparent 41px)",
    backgroundSize: "82px 47px",
    backgroundAttachment: "fixed",
    backgroundPosition: "top center",
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 64,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
    },

    [theme.fn.smallerThan("xs")]: {
      marginBottom: 24,
    },
  },

  titleOuter: {
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
    },
  },

  innerTitle: {
    fontSize: 16,
    marginTop: 16,
  },

  line: {
    ".mantine-Timeline-item": {
      display: "flex",
      alignItems: "center",
      paddingBottom: 24,
      paddingLeft: 32,

      "&::before": {
        borderColor: theme.colors.green[7],
      },

      ".mantine-Timeline-itemBody": {
        fontSize: 16,

      },

      ".mantine-Timeline-itemBullet": {
        top: -10,
        background: theme.fn.linearGradient(90, theme.colors.teal[7], theme.colors.green[7]),
        borderColor: theme.colors.green[7],

      },
    },
  },

  inner: {
    display: "flex",
    justifyContent: "space-evenly",

    [theme.fn.smallerThan("md")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column-reverse",
      gap: 32,
    },
  },

  content: {
    maxWidth: "45%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  rightBox: {
    flex: 1,
    maxWidth: "45%",

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  downloadBox: {
    paddingRight: 48,
    textAlign: "right",
    position: "relative",
    top: -10,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  buttonSmall: {
    marginTop: 48,

    [theme.fn.smallerThan("md")]: {
      marginTop: 24,
    },
  },
}))

export function TimeLine(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)

  return (
    <div id="program" className={classes.wrapper}>
      <Container size={1320}>
        <Center className={classes.title}>
          <Title order={2} align="center" className={classes.titleOuter}>Программа обучения</Title>
          <Text align="center" className={classes.innerTitle} c="dimmed">11 учебных модулей</Text>
        </Center>

        <Flex className={classes.inner}>

          <Box className={classes.content}>
            <Timeline className={classes.line} active={11} bulletSize={40} lineWidth={3}>
              <Timeline.Item bullet={<IconNorthStar size={24}/>} title="Введение в специальность"/>
              <Timeline.Item bullet={<IconChartBar size={24}/>}
                             title="Основы доказательной медицины и медицинской статистики"/>
              <Timeline.Item bullet={<IconLungs size={24}/>}
                             title="Введение в анатомию. Нормальная физиология человека"/>
              <Timeline.Item bullet={<IconShieldLock size={24}/>} title="Пищевая безопасность и гигиена питания"/>
              <Timeline.Item bullet={<IconFlask size={24}/>} title="Биологическая химия"/>
              <Timeline.Item bullet={<IconPhysotherapist size={24}/>} title="Основы патологической физиологии"/>
              <Timeline.Item bullet={<IconCarrot size={24}/>} title="Питание человека и общая нутрициология"/>
              <Timeline.Item bullet={<IconCherry size={24}/>} title="Практическая нутрициология"/>
              <Timeline.Item bullet={<IconVaccineBottle size={24}/>} title="Спортивное питание и БАДы"/>
              <Timeline.Item bullet={<IconUsers size={24}/>}
                             title="Питание специализированных групп населения и при патологии"/>
              <Timeline.Item bullet={<IconStarHalfFilled size={24}/>} title="Перспективы работы нутрициолога"/>
            </Timeline>
          </Box>

          <Flex direction="column" justify="flex-end" className={classes.rightBox}>
            <Box className={classes.downloadBox}>
            </Box>
            <Image objectFit="contain" sx={{ borderRadius: 20 }} src="assets/undraw_absorbed_in_re_ymd6.svg"/>
          </Flex>

        </Flex>

        <Center className={classes.buttonSmall}>
          <Button
            radius="xl"
            variant="gradient"
            gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
            size={md ? "md" : "lg"}
            component="a"
            href="https://t.me/programm_nutricionist_bot"
            target="_blank"
          >
            {md ? "Получить программу обучения" : "Получить подробную программу обучения"}
          </Button>
        </Center>

      </Container>
    </div>

  )
}
