import {
  About,
  Features,
  Footer,
  Form,
  Hero,
  Menu,
  Prices,
  Subjects,
  AboutTraining,
  Team,
  TimeLine,
} from "../components"
import React from "react"
import { ReviewCarusel } from "../components/reviewCarusel"
import { Faq } from "../components/faq"
import { License } from "../components/License"
import { Docs } from "../components/docs"
import { TestDrive } from "../components/testDrive"
import { YouLearned } from "../components/youLearned"
import { Gpt } from "../components/Gpt"
import { FourSteps } from "../components/FourSteps"
import { Salary } from "../components/Salary"

export function MainPage(): React.JSX.Element {
  return (
    <>
      <Menu/>
      <Hero/>
      <About/>
      <Features/>
      <Team/>
      <AboutTraining/>
      <TimeLine/>
      <YouLearned/>
      <Docs/>
      <Subjects/>
      <Gpt/>
      <License/>
      <ReviewCarusel/>
      <Prices/>
      <TestDrive/>
      <Salary/>
      <FourSteps/>
      <Faq/>
      <Form/>
      <Footer/>
    </>
  )
}
