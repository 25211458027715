import { Text, Container, Title, createStyles, Button, Flex, Paper, Grid, Image } from "@mantine/core"
import React from "react"
import { useMediaQuery } from "@mantine/hooks"
import { IconZoomCheck } from "@tabler/icons-react"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  imageWrapper: {
    position: "relative",

    [theme.fn.smallerThan("sm")]: {
      padding: 20,
    },
  },

  image: {
    position: "absolute",
    right: "10%",
    top: "60%",
    transform: "translate(0, -20%) scale(0.8) rotate(-20deg)",

    [theme.fn.smallerThan("md")]: {
      transform: "translate(0, -40%) scale(0.9)",
    },

    [theme.fn.smallerThan("sm")]: {
      position: "static",
      transform: "translate(0, 0) scale(0.9)",
      maxWidth: 480,
      margin: "0 auto",
    },
  },

  content: {
    padding: "120px 64px",
    fontSize: theme.fontSizes.lg,
    gap: 24,

    [theme.fn.smallerThan(1024)]: {
      padding: "96px 48px",
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: theme.fontSizes.md,
      padding: 20,
    },
  },

  inner: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    marginBottom: 64,
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  btn: {
    [theme.fn.smallerThan(768)]: {
      alignSelf: "center",
    },
  },
}))

// ----------------------------------------------------------------------

export function License(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)

  return (
    <Container id="licence" size={1320} className={classes.inner}>
      <Title align="center" className={classes.title}>
        Почему нам доверяют более <Text span variant="gradient"
                                        gradient={{
                                          from: "orange.5",
                                          to: "red.8",
                                          deg: 105,
                                        }}>10.000</Text> студентов?
      </Title>

      <Paper bg="#eeebe3" radius="xl">
        <Grid align="center" justify="center" gutter={0} p={0}>
          <Grid.Col xs={12} sm={8} order={2} orderSm={1}>
            <Flex direction="column" align="flex-start" className={classes.content}>
              <Text>
                Наша организация осуществляет образовательную деятельность на основании{" "}
                <Text span variant="gradient"
                      gradient={{ from: "orange.5", to: "red.8", deg: 105 }}>
                  государственной лицензии
                </Text>{" "}
                и выдает документы установленного образца.
              </Text>
              <Button
                leftIcon={<IconZoomCheck/>}
                className={classes.btn}
                component="a"
                target="_blanck"
                href="https://islod.obrnadzor.gov.ru/rlic/details/fc0ce866-2c42-f41f-cee1-67bd2a3787e2/"
                radius="xl"
                size={md ? "md" : "lg"}
                variant="gradient"
                gradient={{ from: "orange.5", to: "red.8", deg: 105 }}
              >
                Проверить лицензию
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col xs={12} sm={4} order={1} orderSm={2} className={classes.imageWrapper}>
            <Image className={classes.image} src="assets/license.png"/>
          </Grid.Col>
        </Grid>
      </Paper>
    </Container>
  )
}
