import {
  Text,
  Container,
  ThemeIcon,
  Title,
  SimpleGrid,
  createStyles,
  rem,
  AspectRatio,
} from "@mantine/core"
import { IconBooks, IconBrandHipchat, IconPencil, IconSchool } from "@tabler/icons-react"
import { useMediaQuery } from "@mantine/hooks"
import React from "react"

const data = [
  {
    Icon: IconBooks,
    description: "Изучаете материал на платформе в любое удобное время",
  },
  {
    Icon: IconBrandHipchat,
    description: "Общаетесь с экспертами и единомышленниками в Telegram",
  },
  {
    Icon: IconPencil,
    description: "Выполняете практические задания, получаете обратную связь и закрепляете знания",
  },
  {
    Icon: IconSchool,
    description: "Сдаете тестирование и получаете образовательные документы",
  },
]

const useStyles = createStyles((theme) => ({
  inner: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  item: {
    display: "flex",
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },

  title: {
    marginBottom: 64,
    fontSize: 48,
    lineHeight: 1.6,

    [theme.fn.smallerThan("md")]: {
      textAlign: "center",
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  description: {
    textAlign: "center",
    marginTop: theme.spacing.xs,
  },

  highlight: {
    position: "relative",
    color: theme.white,
    background: theme.fn.linearGradient(90, theme.colors.teal[7], theme.colors.green[7]),
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },

  videoContainer: {
    borderRadius: theme.radius.xl,
    overflow: "hidden",
  },

  video: {
    height: "100%",
    objectFit: "contain",
    width: "100%",
    position: "relative",
  },
}))

export function FourSteps(): React.JSX.Element {
  const { classes, theme } = useStyles()
  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const items = data.map(({ description, Icon }, index) => (
    <div className={classes.item} key={index}>
      <ThemeIcon size="xl" variant="gradient" gradient={{ from: "teal.7", to: "green.7" }} className={classes.itemIcon}>
        <Icon size="2rem"/>
      </ThemeIcon>

      <Text fw={500} fz="md">
        {description}
      </Text>
    </div>
  ))

  return (
    <Container size="xl" className={classes.inner}>
      <Title className={classes.title} maw={768}>
        <span className={classes.highlight}>4</span> шага к переменам в карьере и жизни
      </Title>

      <AspectRatio
        ratio={matches ? 4 / 3 : 21 / 9}
        className={classes.videoContainer}
      >
        <video
          className={classes.video}
          poster="assets/promo-poster.jpg"
          controlsList="nodownload"
          muted
          loop
          playsInline
          autoPlay
          src={
            matches
              ? "assets/promo_mobile.mp4"
              : "assets/promo.mp4"
          }
        />
      </AspectRatio>

      <SimpleGrid
        cols={2}
        spacing={48}
        breakpoints={[ { maxWidth: 550, cols: 1, spacing: 24 } ]}
        style={{ marginTop: 30 }}
      >
        {items}
      </SimpleGrid>
    </Container>
  )
}
